import { Component } from "@angular/core";

@Component({
  selector: "app-page-not-found",
  standalone: true,
  imports: [],
  templateUrl: "./page-not-found.component.html",
  styleUrl: "./page-not-found.component.scss"
})
export class PageNotFoundComponent {

}
