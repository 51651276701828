import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "@pages/page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth/auth.guard";
import { NgModule } from "@angular/core";

export const routes: Routes = [


  {
    path: "main-app",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/main-app-modules/main-app-modules.module").then(
        (m) => m.MainAppModulesModule,
      ),
  },
  {
    path: "enterprise",
    loadChildren: () =>
      import("./pages/enterprise-modules/enterprise-modules.module").then(
        (m) => m.EnterpriseModulesModule,
      ),
  },
  {
    path: "questionnaire-flow/:companyName",
    loadChildren: () =>
      import("./pages/custom-flow-modules/custom-flow-modules.module").then(
        (m) => m.CustomFlowModulesModule,
      ),
  },
  {
    path: "flow",
    loadChildren: () =>
      import("./pages/custom/custom.module").then((m) => m.CustomModule),
  },

  {
    path: "web/:companyName",
    loadChildren: () =>
      import("./pages/flow-modules/flow-modules.module").then(
        (m) => m.FlowModulesModule,
      ),
  }, {
    path: "",
    loadChildren: () =>
      import("./pre-oauth-pages/user-sign-up/user-sign-up/user-sign-up.module").then(
        (m) => m.UserSignUpModule,
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pre-oauth-pages/user-sign-up/user-sign-up/user-sign-up.module").then(
        (m) => m.UserSignUpModule,
      ),
  },



  { path: "**",  component:PageNotFoundComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRouteModule {}
